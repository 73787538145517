import { useContext, useState, useCallback } from 'react';
import { LanguageContext } from './LanguageContext';
import { Language } from './types';
import { translations } from './translations';

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}

export function useLanguageProvider() {
  const [language, setLanguage] = useState<Language>('de');
  
  const handleSetLanguage = useCallback((newLanguage: Language) => {
    setLanguage(newLanguage);
    document.documentElement.dir = newLanguage === 'ar' ? 'rtl' : 'ltr';
  }, []);

  return {
    language,
    setLanguage: handleSetLanguage,
    translations: translations[language]
  };
}