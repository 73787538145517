import { useState } from 'react';
import {FaChevronRight} from 'react-icons/fa';
import NewsModal from './News/NewsModal';
import { useLanguage } from '../contexts/LanguageContext';
import {ImageTranslation} from "../translations/image.ts";

const News = () => {
  const [visibleSlides, setVisibleSlides] = useState([0, 1, 2]);
  const [showNewsletterModal, setShowNewsletterModal] = useState(false);
  const { translations } = useLanguage();


  const { language } = useLanguage(); 
  const picture = ImageTranslation[language]; 


  const slides = [
    {
      image: '../../../assets/KI-Podcast_Niedersachsen-next.jpg',
      title: translations.PodcastbeiNIEDERSACHSEN,
      grid: 2,
      link: 'https://digitalagentur-niedersachsen.de/podcast-novobit-ki-zukunft/'
    },
    {
      image: '../../../assets/Pictures/newsletter_novobit_25_1.png',
      title: '',
      grid: 1,
      isNewsletter: true,
      pdfUrl: translations.pdfUrlNewsLetter, 
      srcPicture: picture.newsLetter,
      titleModal: ''
    },
    {
      image: '../../../assets/Pictures/GEC_Ramzi_Guetari_Software_Development.jpg',
      title: translations.GERMANEMIRATES,
      grid: 2,
      isNewsletter: true,
      srcPicture: picture.newsLetterRamzi,
    }
  ];

  
  
  const [indexPicture, setIndexPicture] = useState<number | undefined>()
  const onClosePopUp = () => {
     setShowNewsletterModal(false)
    setIndexPicture(undefined)
  }
  
  const nextSlide = () => {
    setVisibleSlides(prev => {
      const newSlides = [...prev];
      const lastIndex = newSlides[2];
      newSlides.shift();
      newSlides.push((lastIndex + 1) % slides.length);
      return newSlides;
    });
  };

  
  const handleSlideClick = (slideIndex: number) => {
      setIndexPicture(slideIndex)
     const slide = slides[slideIndex];
    if (slide.link && slide.link.length > 0) {
      window.open('https://digitalagentur-niedersachsen.de/podcast-novobit-ki-zukunft/', '_blank', '');
     } else if (slide.isNewsletter) {

      setShowNewsletterModal(true);
    }
  };

 
  
  return (
    <div className="bg-[#a2a7aa] py-5">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-light text-white mb-5">{translations.news.toLowerCase()}</h2>
  
        <div className="relative">
          <div className="grid h-full grid-cols-5 gap-3">
            {visibleSlides.map((slideIndex) => (
              <div 
                key={slideIndex} 
                className={`relative col-span-${slides[slideIndex].grid} cursor-pointer`}
                onClick={() => handleSlideClick(slideIndex)}
              >
                <div className="relative rounded-lg overflow-hidden shadow-lg group">
                  <img 
                    src={slides[slideIndex].image} 
                    className="w-full h-full  object-cover transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <div className="absolute bottom-6 left-6 text-white">
                      <h3 className="text-xl font-light">{slides[slideIndex].title}</h3>
                      <p className="text-sm">{translations.ClickLearnMore}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-6 bg-white/80 hover:bg-white text-gray-800 p-3 rounded-full shadow-lg transition-all duration-200 focus:outline-none"
            aria-label="Next slide"
          >
            <FaChevronRight className="w-6 h-6" />
          </button>
        </div>
      </div>


      {showNewsletterModal && indexPicture &&  <NewsModal
          isOpen={showNewsletterModal}
          onClose={() => onClosePopUp()}
          title={slides[indexPicture].titleModal}
          pdfUrl={slides[indexPicture].pdfUrl}
          srcPicture={slides[indexPicture].srcPicture}
      />
      }
        

    </div>
  );
};

export default News;
