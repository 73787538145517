import { useState, useRef, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { useLanguage } from '../../contexts/LanguageContext';

const HomeNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { language, setLanguage, translations } = useLanguage();

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  return (
    <nav className={`fixed w-full z-50 transition-colors duration-300 ${hasScrolled ? 'bg-white/90 backdrop-blur-sm' : 'bg-transparent'}`}>
      <div className="max-w-[1920px] mx-auto px-6 lg:px-12">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <div className="flex-shrink-0">
            <img src={hasScrolled ? "../../assets/Logo_png/novobit_Logo_red_black_rgb.png" : "../../assets/Logo_png/novobit_Logo_red_white_rgb.png"} alt="Novobit" className="h-20" />
          </div>

          {/* Right Side Menu */}
          <div className="flex items-center space-x-4">
            {/* Language Selector */}
            <div className="flex items-center space-x-2 text-sm">
              <button 
                onClick={() => setLanguage('de')}
                className={`${language === 'de' ? 'text-red-600' : 'text-gray-400'} hover:text-red-600`}
              >
                de
              </button>
              <span className="text-gray-300">|</span>
              <button 
                onClick={() => setLanguage('en')}
                className={`${language === 'en' ? 'text-red-600' : 'text-gray-400'} hover:text-red-600`}
              >
                en
              </button>
              <span className="text-gray-300">|</span>
              {/*<button */}
              {/*  onClick={() => setLanguage('ar')}*/}
              {/*  className={`${language === 'ar' ? 'text-red-600' : 'text-gray-400'} hover:text-red-600`}*/}
              {/*>*/}
              {/*  ar*/}
              {/*</button>*/}
            </div>

            {/* Dropdown Menu Button */}
            <div className="relative" ref={dropdownRef}>
              <button 
                className="p-2 hover:bg-white/10 rounded-full transition-colors"
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
                aria-haspopup="true"
              >
                <FaBars className="h-5 w-5 text-gray-700" />
              </button>

              {/* Dropdown Menu */}
              {isOpen && (
                <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white/90 backdrop-blur-sm ring-1 ring-black ring-opacity-5">
                  <div className="py-5" role="menu" aria-orientation="vertical">
                    <button
                      onClick={() => scrollToSection('about')}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-red-600"
                      role="menuitem"
                    >
                      {translations.about}
                    </button>
                    <button
                      onClick={() => scrollToSection('software-services')}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-red-600"
                      role="menuitem"
                    >
                      {translations.software}
                    </button>
                    <button
                      onClick={() => scrollToSection('ai-expertise')}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-red-600"
                      role="menuitem"
                    >
                      {translations.ai}
                    </button>
                    <button
                      onClick={() => scrollToSection('akademie')}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-red-600"
                      role="menuitem"
                    >
                      {translations.academy}
                    </button>
                    <button
                      onClick={() => scrollToSection('career')}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-red-600"
                      role="menuitem"
                    >
                      {translations.career}
                    </button>
                    <button
                      onClick={() => scrollToSection('contact')}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-red-600"
                      role="menuitem"
                    >
                      {translations.contact}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HomeNavbar;
