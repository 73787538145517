export const accessTranslations = {
  de: {
    title: 'Auskunft',
    description: 'Jederzeit geben wir auf Anfrage unentgeltlich Auskunft darüber, welche personenbezogenen Daten, deren\n' +
        'Herkunft oder Empfänger und zu welchem Zweck wir diese gespeichert haben. Sie haben außerdem das\n' +
        'Recht, Ihre Daten etc. anzufordern und/oder uns aufzufordern, alle von Ihnen gespeicherten Daten zu\n' +
        'löschen und nicht weiter zu verwenden. Bitte wenden Sie sich dazu – oder natürlich auch sonst betreffend\n' +
        'aller Fragen zu Ihren Daten – per E-Mail, postalisch, persönlich oder telefonisch direkt an datenschutz [at]\n' +
        'novobit.eu.\n'
  },
  en: {
    title: 'Information Access',
    description: 'At any time, we will provide information free of charge about what personal data we have stored, its origin or recipients, and for what purpose. You also have the right to request your data and/or ask us to delete all your stored data and not use it further. For this purpose – or of course for any other questions regarding your data – please contact us directly by email, mail, in person, or by phone at datenschutz [at] novobit.eu.'
  },
  ar: {
    title: 'الوصول إلى المعلومات',
    description: 'في أي وقت، سنقدم معلومات مجانية حول البيانات الشخصية التي قمنا بتخزينها، ومصدرها أو المستلمين، والغرض من ذلك. لديك أيضاً الحق في طلب بياناتك و/أو مطالبتنا بحذف جميع بياناتك المخزنة وعدم استخدامها مرة أخرى. لهذا الغرض - أو بالطبع لأي أسئلة أخرى تتعلق ببياناتك - يرجى الاتصال بنا مباشرة عبر البريد الإلكتروني أو البريد العادي أو شخصياً أو عبر الهاتف على datenschutz [at] novobit.eu.'
  }
};
