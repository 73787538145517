import React from 'react';
import {useLanguage} from "../../contexts/LanguageContext.tsx"; 

interface VideoCardProps {
  videoSrc: string;
  altImageSrc: string;
  hoverImageSrc?: string;
  title?: string;
  onClick?: () => void;
  showOverlay?: boolean;
}

const VideoCard: React.FC<VideoCardProps> = ({
  videoSrc,
  altImageSrc,
  hoverImageSrc,
  title,
  onClick,
  showOverlay = true
}) => {

  const { translations } = useLanguage();

  

  return (
    <div 
      className={`relative group h-full overflow-hidden rounded-lg ${onClick ? 'cursor-pointer' : ''}`}
      onClick={onClick}
    >
      <div className="relative h-full">
        <video 
          autoPlay 
          muted 
          loop
          playsInline
          className={`absolute min-h-full min-w-full object-cover top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300 ${hoverImageSrc ? 'group-hover:opacity-0' : ''}`}
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          <source src={videoSrc} type="video/mp4" />
          <img 
            src={altImageSrc}
            alt={title || "Video fallback"}
            className="absolute min-h-full min-w-full object-cover top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </video>
        
        {hoverImageSrc && (
          <img 
            src={hoverImageSrc}
            alt={`${title || 'Hover'} Alternate`}
            className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          />
        )}

        {showOverlay && (
          <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            {title && (
              <div className="absolute bottom-6 left-6 text-white">
                <h3 className="text-xl font-light">{title}</h3>
                {onClick && <p className="text-sm">{translations.ClickLearnMore}</p>}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCard;
