export const dataProtectionOfficerTranslations = {
  de: {
    title: 'Name und Anschrift des Datenschutzbeauftragten',
    description: 'Der Datenschutzbeauftragte des Unternehmens ist:',
    contact: {
      name: 'Nils Lippert',
      address: 'Wilhelm-Busch-Weg 3',
      city: '31185 Söhlde/OT Nettlingen',
      phone: 'Telefon: 05123 409 84 32',
      fax: 'Telefax: 05123 409 84 33',
      email: 'E-Mail: nilslippert@me.com'
    }
  },
  en: {
    title: 'Name and Address of the Data Protection Officer',
    description: 'The company\'s Data Protection Officer is:',
    contact: {
      name: 'Nils Lippert',
      address: 'Wilhelm-Busch-Weg 3',
      city: '31185 Söhlde/OT Nettlingen',
      phone: 'Phone: 05123 409 84 32',
      fax: 'Fax: 05123 409 84 33',
      email: 'Email: nilslippert@me.com'
    }
  },
  ar: {
    title: 'اسم وعنوان مسؤول حماية البيانات',
    description: 'مسؤول حماية البيانات في الشركة هو:',
    contact: {
      name: 'نيلز ليبرت',
      address: 'فيلهلم-بوش-فيج 3',
      city: '31185 زولده/أوت نتلنجن',
      phone: 'هاتف: 05123 409 84 32',
      fax: 'فاكس: 05123 409 84 33',
      email: 'البريد الإلكتروني: nilslippert@me.com'
    }
  }
};