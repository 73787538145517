
export const ImageTranslation = {
    de: {
        about_1_1: "../../assets/Pictures/Brunis_Braunschweig_and_Tunis_novobit.jpg",
        about_1_2: "../../assets/Pictures/Brunis_PopUp_d.png" ,
        about_2_1: "../../assets/Pictures/DevOps-Kultur_novobit.png" ,
        about_2_2: "../../assets/Pictures/Historie_novobit.png",
        about_3: "../../assets/_Mouse-over/Hatem_Hamzaoui_Managing_Director.png",
        about_4: "../../assets/_Mouse-over/novobit_dubai_Vertrieb.png",
        softwareservices_1_1: "../../assets/Pictures/Portfolio_novobit_Software-Entwicklung.jpg",
        softwareservices_1_2: "../../assets/_Pop-ups/DevOps_Leistungen.png",
        softwareservices_2_1: "../../assets/Pictures/Software_Entwicklung_Process_User_Story_Life_Cycle.jpg",
        softwareservices_2_2: "../../assets/_Pop-ups/KI_Expertise_novobit_Beratung.png",
        softwareservices_3: "../../assets/_Mouse-over/Ingo_Ruebel_Software-Projekte_1.png",
        softwareservices_4: "../../assets/_Mouse-over/Nada_Hamzaoui_Ansprechpartnerin_Tunis.png",
        softwareservices_5: "../../assets/_Pop-ups/Planungssoftware_serielle_Fertigung.png",
        softwareservices_6: "../../assets/_Pop-ups/Planungssoftware_Reporting_Tool.png",
        softwareservices_7: "../../assets/_Mouse-over/Informationssicherheit_novobit.png",
        softwareservices_8: "../../assets/_Pop-ups/digitale_Signage-Systeme.png",
        ki_expert_1_1: "../../assets/Pictures/AI-Lab_novobit_Know-how.jpg",
        ki_expert_1_2: "../../assets/_Pop-ups/KI_Expertise_novobit_Beratung.png",
        ki_expert_2: "../../assets/_Mouse-over/novobit_KI-Lab_Gruendungsteam.png",
        ki_expert_3: "../../assets/_Pop-ups/KI_Expertise_novobit_smartmaps.png",
        ki_expert_4: "../../assets/_Mouse-over/Ramzi_Guetari_KI-Programmdirektor.png",
        ki_expert_5: "../../assets/_Pop-ups/KI_Expertise_novobit_smartfakes.png",
        ki_expert_6_1: "../../assets/Pictures/Visions_novobit.jpg",
        ki_expert_6_2: "../../assets/_Pop-ups/KI_Expertise_ogi.png",
        ki_expert_7: "../../assets/_Pop-ups/novobit_Produktentwicklung_devally.png",
        academy_1: "../../assets/Pictures/Akademie_KI-Entwickler.jpg",
        academy_2: "../../assets/_Pop-ups/Akademie_novobit.jpg",
        career_1_1: "../../assets/Pictures/Braunschweig_Panorama_Aussicht.jpg",
        career_1_2: "../../assets/_Pop-ups/novobit_Benefits_Tunis.png",
        career_2: "../../assets/_Pop-ups/novobit_Bewerbungsprozess.png",
        career_3_1: "../../assets/Pictures/novobit_Jobs_Braunschweig.png",
        career_3_2: "../../assets/_Pop-ups/novobit_Jobs_in_Braunschweig_Liste.png",
        career_4_1: "../../assets/Pictures/Tunis_Panorama_Aussicht.jpg",
        career_4_2: "../../assets/_Pop-ups/novobit_Benefits_Tunis.png",
        career_5: "../../assets/_Pop-ups/novobit_Bewerbungsprozess_Tunis.png",
        career_6_1: "../../assets/Pictures/novobit_Jobs_Tunis.png",
        career_6_2: "../../assets/_Pop-ups/novobit_Jobs_in_Tunis_Liste.png",
        contact_1: "../../assets/Pictures/Kontakt_novobit.jpg",
        conatct_2: "../../assets/_Pop-ups/novobit_Kontakte.png",
        newsLetterRamzi: "../../../assets/_Pop-ups/GEC_Zukunft_Software-Entwicklung.png" ,
        brunisPopUp: "../../assets/Pictures/Brunis_PopUp_d.png",
        novobit_in_picture: "../../assets/Pictures/novobit_in_Zahlen1.png",
        newsLetter: "../../../assets/_Pop-ups/newsletter_25_1.png"      ,
        devopsCulture: "../../assets/Pictures/DevOps-Kultur_novobit.png"      ,
    },
    en: {
        about_1_1: "../../assets/Pictures/novobit_Braunschweig.jpg",
        about_1_2: "../../assets/Pictures/Brunis_PopUp_d.png" ,
        about_2_1: "../../assets/Pictures/DevOps-Kultur_novobit.png" ,
        about_2_2: "../../assets/Pictures/Historie_novobit.png",
        about_3: "../../assets/_Mouse-over/Hatem_Hamzaoui_Managing_Director_en.png",
        about_4: "../../assets/_Mouse-over/novobit_dubai_Vertrieb_En.png", 
        softwareservices_1_1: "../../assets/Pictures/Portfolio_novobit_Software-Entwicklung.jpg",
        softwareservices_1_2: "../../assets/_Pop-ups/DevOps_Leistungen.png",
        softwareservices_2_1: "../../assets/Pictures/Software_Entwicklung_Process_User_Story_Life_Cycle.jpg",
        softwareservices_2_2: "../../assets/_Pop-ups/KI_Expertise_novobit_Beratung.png",
        softwareservices_3: "../../assets/_Mouse-over/Ingo_Ruebel_Software-Projekte_1.png",
        softwareservices_4: "../../assets/_Mouse-over/Nada_Hamzaoui_Ansprechpartnerin_Tunis.png",
        softwareservices_5: "../../assets/_Pop-ups/Planungssoftware_serielle_Fertigung.png",
        softwareservices_6: "../../assets/_Pop-ups/Planungssoftware_Reporting_Tool.png",
        softwareservices_7: "../../assets/_Mouse-over/Informationssicherheit_novobit.png",
        softwareservices_8: "../../assets/_Pop-ups/digitale_Signage-Systeme.png",
        ki_expert_1_1: "../../assets/Pictures/AI-Lab_novobit_Know-how.jpg",
        ki_expert_1_2: "../../assets/_Pop-ups/KI_Expertise_novobit_Beratung.png",
        ki_expert_2: "../../assets/_Mouse-over/novobit_KI-Lab_Gruendungsteam.png",
        ki_expert_3: "../../assets/_Pop-ups/KI_Expertise_novobit_smartmaps.png",
        ki_expert_4: "../../assets/_Mouse-over/Ramzi_Guetari_KI-Programmdirektor.png",
        ki_expert_5: "../../assets/_Pop-ups/KI_Expertise_novobit_smartfake_en.png",
        ki_expert_6_1: "../../assets/Pictures/Visions_novobit.jpg",
        ki_expert_6_2: "../../assets/_Pop-ups/KI_Expertise_ogi.png",
        ki_expert_7: "../../assets/_Pop-ups/novobit_Produktentwicklung_devally.png",
        academy_1: "../../assets/Pictures/Akademie_KI-Entwickler.jpg",
        academy_2: "../../assets/_Pop-ups/Akademie_novobit.jpg",
        career_1_1: "../../assets/Pictures/Braunschweig_Panorama_Aussicht.jpg",
        career_1_2: "../../assets/_Pop-ups/novobit_Benefits_Tunis.png",
        career_2: "../../assets/_Pop-ups/novobit_Bewerbungsprozess.png",
        career_3_1: "../../assets/Pictures/novobit_Jobs_Braunschweig.png",
        career_3_2: "../../assets/_Pop-ups/novobit_Jobs_in_Braunschweig_Liste.png",
        career_4_1: "../../assets/Pictures/Tunis_Panorama_Aussicht.jpg",
        career_4_2: "../../assets/_Pop-ups/novobit_Benefits_Tunis.png",
        career_5: "../../assets/_Pop-ups/novobit_Bewerbungsprozess_Tunis.png",
        career_6_1: "../../assets/Pictures/novobit_Jobs_Tunis.png",
        career_6_2: "../../assets/_Pop-ups/novobit_Jobs_in_Tunis_Liste.png",
        contact_1: "../../assets/Pictures/Kontakt_novobit.jpg",
        conatct_2: "../../assets/_Pop-ups/novobit_Kontakte.png"    ,
        newsLetterRamzi: "../../../assets/_Pop-ups/GEC_Future_Software_DevelopmentEng.png"    ,
        brunisPopUp: "../../assets/Pictures/Brunis_PopUp_e.png"      ,
        novobit_in_picture: "../../assets/Pictures/novobit_in_figures.png"  ,
        newsLetter: "../../../assets/_Pop-ups/newsletter_25_1_en.png"      ,
        devopsCulture: "../../assets/Pictures/DevOps-Kultur_novobit_EN.png"      ,

    },
    ar: {
        about_1_1: "../../assets/Pictures/novobit_Braunschweig.jpg",
        about_1_2: "../../assets/Pictures/Brunis_PopUp_d.png" ,
        about_2_1: "../../assets/Pictures/DevOps-Kultur_novobit.png" ,
        about_2_2: "../../assets/Pictures/Historie_novobit.png",
        about_3: "../../assets/_Mouse-over/Hatem_Hamzaoui_Managing_Director.png",
        about_4: "../../assets/_Mouse-over/novobit_dubai_Vertrieb.png",
        softwareservices_1_1: "../../assets/Pictures/Portfolio_novobit_Software-Entwicklung.jpg",
        softwareservices_1_2: "../../assets/_Pop-ups/DevOps_Leistungen.png",
        softwareservices_2_1: "../../assets/Pictures/Software_Entwicklung_Process_User_Story_Life_Cycle.jpg",
        softwareservices_2_2: "../../assets/_Pop-ups/KI_Expertise_novobit_Beratung.png",
        softwareservices_3: "../../assets/_Mouse-over/Ingo_Ruebel_Software-Projekte_1.png",
        softwareservices_4: "../../assets/_Mouse-over/Nada_Hamzaoui_Ansprechpartnerin_Tunis.png",
        softwareservices_5: "../../assets/_Pop-ups/Planungssoftware_serielle_Fertigung.png",
        softwareservices_6: "../../assets/_Pop-ups/Planungssoftware_Reporting_Tool.png",
        softwareservices_7: "../../assets/_Mouse-over/Informationssicherheit_novobit.png",
        softwareservices_8: "../../assets/_Pop-ups/digitale_Signage-Systeme.png",
        ki_expert_1_1: "../../assets/Pictures/AI-Lab_novobit_Know-how.jpg",
        ki_expert_1_2: "../../assets/_Pop-ups/KI_Expertise_novobit_Beratung.png",
        ki_expert_2: "../../assets/_Mouse-over/novobit_KI-Lab_Gruendungsteam.png",
        ki_expert_3: "../../assets/_Pop-ups/KI_Expertise_novobit_smartmaps.png",
        ki_expert_4: "../../assets/_Mouse-over/Ramzi_Guetari_KI-Programmdirektor.png",
        ki_expert_5: "../../assets/_Pop-ups/KI_Expertise_novobit_smartfake.png",
        ki_expert_6_1: "../../assets/Pictures/Visions_novobit.jpg",
        ki_expert_6_2: "../../assets/_Pop-ups/KI_Expertise_ogi.png",
        ki_expert_7: "../../assets/_Pop-ups/novobit_Produktentwicklung_devally.png",
        academy_1: "../../assets/Pictures/Akademie_KI-Entwickler.jpg",
        academy_2: "../../assets/_Pop-ups/Akademie_novobit.jpg",
        career_1_1: "../../assets/Pictures/Braunschweig_Panorama_Aussicht.jpg",
        career_1_2: "../../assets/_Pop-ups/novobit_Benefits_Tunis.png",
        career_2: "../../assets/_Pop-ups/novobit_Bewerbungsprozess.png",
        career_3_1: "../../assets/Pictures/novobit_Jobs_Braunschweig.png",
        career_3_2: "../../assets/_Pop-ups/novobit_Jobs_in_Braunschweig_Liste.png",
        career_4_1: "../../assets/Pictures/Tunis_Panorama_Aussicht.jpg",
        career_4_2: "../../assets/_Pop-ups/novobit_Benefits_Tunis.png",
        career_5: "../../assets/_Pop-ups/novobit_Bewerbungsprozess_Tunis.png",
        career_6_1: "../../assets/Pictures/novobit_Jobs_Tunis.png",
        career_6_2: "../../assets/_Pop-ups/novobit_Jobs_in_Tunis_Liste.png",
        contact_1: "../../assets/Pictures/Kontakt_novobit.jpg",
        conatct_2: "../../assets/_Pop-ups/novobit_Kontakte.png"      ,
        newsLetterRamzi: "../../../assets/_Pop-ups/GEC_Future_Software_DevelopmentEng.png"      ,
        newsLetter: "../../../assets/_Pop-ups/newsletter_25_1.png"      ,
        brunisPopUp: "../../assets/Pictures/Brunis_PopUp_E.png"         ,
        novobit_in_picture: "../../assets/Pictures/novobit_in_figures.png"    ,
        devopsCulture: "../../assets/Pictures/DevOps-Kultur_novobit.png"    
    }
}
