import MainLayout from '../layouts/MainLayout';
import { useLanguage } from '../contexts/LanguageContext';
import { imprintTranslations } from '../translations/imprint';

const ImprintPage = () => {
  const { language } = useLanguage();
  const t = imprintTranslations[language];

  return (
    <MainLayout>
      <div className="max-w-7xl mx-auto px-4 py-8 mt-20">
        <h1 className="text-3xl font-light mb-3">{t.title}</h1>
        <div className="prose max-w-none space-y-2">
          <p className="text-gray-700">
            {t.ownership}
          </p>
          
          <p className="text-gray-700">
            {t.liability}
          </p>

          <div className="space-y-2">
            <p className="font-medium">{t.company}</p>
            <p className="text-gray-700">
              {t.address.street}<br />
              {t.address.city}<br />
              {t.address.country}
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-gray-700">
              {t.contact.phone}<br />
              {t.contact.fax}<br />
              {t.contact.email}
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-gray-700">
              {t.details.manager}<br />
              {t.details.court}<br />
              {t.details.registration}<br />
              {t.details.vatId}
            </p>
          </div>

          <div className="space-y-2">
            <p className="font-medium">{t.responsible.title}</p>
            <p className="text-gray-700">{t.responsible.name}</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ImprintPage;
