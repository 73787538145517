import { useLanguage } from '../contexts/LanguageContext';

const About = () => {
  const { translations } = useLanguage();

  return (
    <div className="py-5 bg-white mt-10">
      <div className="max-full mx-auto px-4 sm:px-8 lg:px-12 text-center">
      <p className="text-lg text-gray-700 max-w-full mx-auto font-bold">
          {translations.welcome}
        </p>
        <p className="text-mx text-gray-700 max-w-full mx-auto">
          {translations.experience}
        </p>
        <p className="text-mx text-gray-700 max-w-5xl mx-auto">
          {translations.efficiency}
        </p>
      </div>
    </div>
  );
};

export default About;
