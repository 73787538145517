import MainLayout from '../layouts/MainLayout';

const PrivacyHistoryPage = () => {
  return (
    <MainLayout>
      <div className="max-w-7xl mx-auto px-4 py-8 mt-20">
        <h1 className="text-3xl font-light mb-6">Historie der Privatsphäre-Einstellungen</h1>
        <div className="space-y-4">
          {[
            {
              date: '2023-12-20',
              time: '15:30',
              changes: 'Marketing Cookies aktiviert'
            },
            {
              date: '2023-12-15',
              time: '10:45',
              changes: 'Analyse Cookies deaktiviert'
            },
            {
              date: '2023-12-01',
              time: '09:15',
              changes: 'Erste Einstellungen vorgenommen'
            }
          ].map((entry, index) => (
            <div key={index} className="bg-white p-4 rounded-lg shadow">
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm text-gray-600">{entry.date} um {entry.time} Uhr</p>
                  <p className="font-medium">{entry.changes}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default PrivacyHistoryPage;