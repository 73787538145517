import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';

interface NewsModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  srcPicture?: string;
  pdfUrl?: string;
}


const NewsModal: React.FC<NewsModalProps> = ({
                                               isOpen,
                                               onClose,
                                               title,
                                               srcPicture,
                                               pdfUrl
                                             }) => {


    const { translations } = useLanguage();
    
    
  if (!isOpen) return null;

  return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-6xl max-h-[90vh] overflow-y-auto">
              <div className="p-3">
                  <div className="flex justify-between items-start sticky-btn">
                       <h3 className="text-2xl font-light">{title}</h3>

                      <button  onClick={onClose}
                      className="bg-gray-400/50 hover:bg-gray-500/50 transition-colors rounded-full p-2" >
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
              
            <div className="prose max-w-none">
              <img
                  src={srcPicture}
                  className="w-full  pb-3 h-full object-cover rounded-lg" 
              />

              {pdfUrl &&  pdfUrl.length > 0 &&
                  <div className="mt-6 flex justify-center">
                    <a
                        href={pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                    
                        {translations.downloadPdf}
                    </a>
                  </div>
              }

            </div>
          </div>
        </div>
      </div>
  );
};

export default NewsModal;
