export const cookiesTranslations = {
  de: {
    title: 'Cookies',
    description: 'Wir verwenden sog. „Session-Cookies“, um Ihnen die Nutzung unserer Webseiten zu erleichtern. Dabei\n' +
        'handelt es sich um kleine Textdateien, die nur für die Dauer Ihres Besuchs unserer Webseite auf Ihrer Festplatte hinterlegt und abhängig von der Einstellung Ihres Browser-Programms beim Beenden des Browsers\n' +
        'automatisch wieder gelöscht werden. Diese Cookies rufen aber keine auf Ihrer Festplatte über Sie\n' +
        'gespeicherten Informationen ab und beeinträchtigen auch nicht Ihren PC oder Ihre Dateien. Die meisten\n' +
        'Browser sind so eingestellt, dass sie Cookies automatisch akzeptieren. Sie können das Speichern von\n' +
        'Cookies jedoch deaktivieren oder Ihren Browser so einstellen, dass er Sie auf die Sendung von Cookies\n' +
        'hinweist.\n',
    description2: 'Zur Verwaltung der eingesetzten Cookies und ähnlichen Technologien (Tracking-Pixel, Web-Beacons etc.)\n' +
        'und diesbezüglicher Einwilligungen setzen wir das Consent Tool „Real Cookie Banner“ ein. Details zur\n' +
        'Funktionsweise von „Real Cookie Banner“ finden Sie unter https://devowl.io/de/rcb/datenverarbeitung/.\n' +
        'Rechtsgrundlagen für die Verarbeitung von personenbezogenen Daten in diesem Zusammenhang sind Art. 6\n' +
        'Abs. 1 lit. c DS-GVO und Art. 6 Abs. 1 lit. f DS-GVO. Unser berechtigtes Interesse ist die Verwaltung der\n' +
        'eingesetzten Cookies und ähnlicher Technologien und der diesbezüglichen Einwilligungen. Die Bereitstellung\n' +
        'der personenbezogenen Daten ist weder vertraglich vorgeschrieben noch für den Abschluss eines Vertrages\n' +
        'notwendig. Sie sind nicht verpflichtet, die personenbezogenen Daten bereitzustellen. Wenn Sie die\n' +
        'personenbezogenen Daten nicht bereitstellen, können wir Ihre Einwilligungen nicht verwalten.\n',
    titleLinkGoogleMaps: 'Google-Maps',
    linkGoogleMaps:'Auf der Seite Kontakt wird eine Google-Maps-Karte dargestellt. Die Einbindung der Karten in die Webseiten\n' +
        'erfolgt über einen Serverabruf bei Google, der auch Cookies verwendet. Google kann unter Umständen\n' +
        'mitlesen, dass Sie unsere Seite besuchen. Die Datenschutzhinweise von Google können Sie hier einsehen:',
    management: 'Zur Verwaltung der eingesetzten Cookies und ähnlichen Technologien (Tracking-Pixel, Web-Beacons etc.) und diesbezüglicher Einwilligungen setzen wir das Consent Tool „Real Cookie Banner" ein.',
    details: 'Details zur Funktions­weise von „Real Cookie Banner" finden Sie unter https://devowl.io/de/rcb/datenverarbeitung/.',
    legal: 'Rechtsgrundlagen für die Verarbeitung von personen­bezogenen Daten in diesem Zusammenhang sind Art. 6 Abs. 1 lit. c DS-GVO und Art. 6 Abs. 1 lit. f DS-GVO.'
  },
  en: {
    title: 'Cookies',

    description: ' We use so-called "session cookies" to make it easier for you to use our website. These are small text files\n' +
        'that are only stored on your hard drive for the duration of your visit to our website and, depending on the\n' +
        'settings of your browser, are automatically deleted again when you close the browser. However, these\n' +
        'cookies do not retrieve any information stored about you on your hard drive and do not affect your PC or\n' +
        'files. Most browsers are set to accept cookies automatically. However, you can disable the storage of\n' +
        'cookies or set your browser to notify you when cookies are sent',
    description2: 'We use the "Real Cookie Banner" consent tool to manage the cookies and similar technologies used\n' +
        '(tracking pixels, web beacons, etc.) and consent in this regard. You can find the details regarding the\n' +
        'functioning of “Real Cookie Banner” under https://devowl.io/de/rcb/datenverarbeitung/.\n' +
        'The legal basis for the processing of personal data in this context is Article 6, paragraph 1, lit. c of the GDPR\n' +
        'and Article 6, paragraph 1, lit. f of the GDPR. Our legitimate interest is the management of the cookies and\n' +
        'similar technologies used and the related consents. The provision of the personal data is neither\n' +
        'contractually required nor necessary for the conclusion of a contract. You are not obliged to provide the\n' +
        'personal data. If you do not provide the personal data, we will not be able to manage your consents.\n',
    titleLinkGoogleMaps: 'Google Maps',
    linkGoogleMaps:'A map from Google-Maps is shown on the Contact page. The maps are integrated into the web pages via a\n' +
        'server call with Google, which also uses cookies. In some cases, Google may be able to read that you are\n' +
        'visiting our site. You can view Google\'s privacy policy here:\n',
    
    management: 'To manage the cookies and similar technologies used (tracking pixels, web beacons, etc.) and related consents, we use the consent tool "Real Cookie Banner".',
    details: 'Details about how "Real Cookie Banner" works can be found at https://devowl.io/de/rcb/datenverarbeitung/.',
    legal: 'The legal bases for the processing of personal data in this context are Art. 6(1)(c) GDPR and Art. 6(1)(f) GDPR.'
  },
  ar: {
    title: 'ملفات تعريف الارتباط',

    description: 'نحن نستخدم ما يُعرف بـ"ملفات تعريف الارتباط للجلسة" لتسهيل استخدامك لمواقعنا الإلكترونية. هذه الملفات هي عبارة عن ملفات نصية صغيرة يتم تخزينها على القرص الصلب الخاص بك فقط خلال مدة زيارتك لموقعنا الإلكتروني، ويتم حذفها تلقائيًا عند إغلاق المتصفح، وذلك بناءً على إعدادات برنامج المتصفح الخاص بك.\n' +
        'ومع ذلك، فإن هذه الملفات لا تسترجع أي معلومات مخزنة عنك على القرص الصلب ولا تؤثر على جهاز الكمبيوتر أو ملفاتك. معظم المتصفحات مُعدة لقبول ملفات تعريف الارتباط تلقائيًا. ومع ذلك، يمكنك تعطيل خيار تخزين ملفات تعريف الارتباط أو ضبط متصفحك بحيث يُعلمك عند إرسال ملفات تعريف الارتباط.',
    description2: 'لإدارة ملفات تعريف الارتباط والتقنيات المماثلة المستخدمة (مثل وحدات تتبع البيكسل، إشارات الويب وغيرها) بالإضافة إلى إدارة الموافقات ذات الصلة، نستخدم أداة الموافقة "Real Cookie Banner". يمكنك العثور على تفاصيل حول كيفية عمل "Real Cookie Banner" على الرابط التالي: https://devowl.io/de/rcb/datenverarbeitung/.\n' +
        '\n' +
        'تستند الأسس القانونية لمعالجة البيانات الشخصية في هذا السياق إلى المادة 6 الفقرة 1 الحرف (ج) من اللائحة العامة لحماية البيانات (DS-GVO) والمادة 6 الفقرة 1 الحرف (و) من اللائحة العامة لحماية البيانات. مصلحتنا المشروعة هي إدارة ملفات تعريف الارتباط والتقنيات المماثلة والموافقات ذات الصلة.\n' +
        '\n' +
        'تقديم البيانات الشخصية ليس مطلوبًا بموجب العقد ولا ضروريًا لإبرام عقد. لست ملزمًا بتقديم البيانات الشخصية. ومع ذلك، إذا لم تقدم البيانات الشخصية، فلن نتمكن من إدارة موافقاتك.',

    titleLinkGoogleMaps: 'Google Maps',
    linkGoogleMaps:'على صفحة "اتصال"، يتم عرض خريطة Google Maps. يتم دمج الخريطة في الموقع من خلال طلب يُرسل إلى خوادم Google، والذي قد يتضمن أيضًا استخدام ملفات تعريف الارتباط. قد تتمكن Google في بعض الحالات من معرفة أنك تزور موقعنا.\n' +
        '\n' +
        'يمكنك الاطلاع على إشعارات الخصوصية الخاصة بـ Google عبر الرابط التالي:',
    
    management: 'لإدارة ملفات تعريف الارتباط والتقنيات المماثلة المستخدمة (بكسلات التتبع، إشارات الويب، إلخ) والموافقات ذات الصلة، نستخدم أداة الموافقة "Real Cookie Banner".',
    details: 'يمكن العثور على تفاصيل حول كيفية عمل "Real Cookie Banner" على https://devowl.io/de/rcb/datenverarbeitung/.',
    legal: 'الأسس القانونية لمعالجة البيانات الشخصية في هذا السياق هي المادة 6(1)(ج) و المادة 6(1)(و) من اللائحة العامة لحماية البيانات.'
  }
};
