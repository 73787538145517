import { useState } from 'react';
import ContactModal from './Contact/ContactModal.tsx';
import ImageCard from './Contact/ImageCard.tsx';
import { useLanguage } from '../contexts/LanguageContext';


const Contact = () => {
  const [showStephanieModal, setShowStephanieModal] = useState(false);
  const [showKontaktModal, setShowKontaktModal] = useState(false);
  const [showKatjaModal, setShowKatjaModal] = useState(false);
  const [showHansModal, setShowHansModal] = useState(false);
  const { translations } = useLanguage();


  return (
    <div id="contact" className="py-5 bg-white">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-light text-gray-900 mb-5">{translations.contact}</h2>
        
        <div className="grid grid-cols-4 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-3">
          {/* Stephanie */}
          <div className="relative   overflow-hidden rounded-lg ">
            <ImageCard
              imageSrc="../../assets/Pictures/Stephanie_Johnson_novobit.jpg"
              hoverImageSrc="../../assets/Pictures/Stephanie_Johnson_novobit.jpg"
              title=" "
              onClick={() => setShowStephanieModal(true)}
            />
          </div>

          {/* Small Talk Section */}
          <div className="relative   overflow-hidden rounded-lg lg:col-span-2">
            <ImageCard
              imageSrc={translations.contact_1_2}
              hoverImageSrc={translations.contact_1_2}
              title=" "
              onClick={() => setShowKontaktModal(true)}
            />
          </div>

          {/* Katja */}
          <div className="relative  overflow-hidden rounded-lg">
            <ImageCard
              imageSrc="../../assets/Pictures/Katja_Ludwig_novobit.jpg"
              hoverImageSrc="../../assets/Pictures/Katja_Ludwig_novobit.jpg"
              title=" "
              onClick={() => setShowKatjaModal(true)}
            />
          </div>

          {/* Hans-Joachim */}
          <div className="relative   overflow-hidden rounded-lg">
            <ImageCard
              imageSrc="../../assets/Pictures/Andreas_Simon_novobit.jpg"
              hoverImageSrc="../../assets/Pictures/Andreas_Simon_novobit.jpg"
              title=" "
              onClick={() => setShowHansModal(true)}
            />
          </div>
        </div>
      </div>

      <ContactModal
        isOpen={showStephanieModal}
        onClose={() => setShowStephanieModal(false)}
        title="Marketing & Communications"
        imageSrc={translations.contact_1}
        description="Stephanie Johnson leitet unsere Marketing- und Kommunikationsabteilung. Mit ihrer langjährigen Erfahrung sorgt sie dafür, dass unsere Botschaften klar und überzeugend bei unseren Zielgruppen ankommen."
        ctaText="Kontakt aufnehmen"
        ctaLink="#"
      />

      <ContactModal
        isOpen={showKontaktModal}
        onClose={() => setShowKontaktModal(false)}
        title="Lassen Sie uns reden"
        imageSrc={translations.contact_1}
        description="Wir freuen uns darauf, Sie kennenzulernen und mehr über Ihre Projekte und Ideen zu erfahren. In einem persönlichen Gespräch können wir gemeinsam herausfinden, wie wir Sie am besten unterstützen können."
        ctaText="Termin vereinbaren"
        ctaLink="#"
      />

      <ContactModal
        isOpen={showKatjaModal}
        onClose={() => setShowKatjaModal(false)}
        title="Office Management"
        imageSrc={translations.contact_1}
        description="Katja Ludwig ist das Herz unseres Office Managements. Sie sorgt dafür, dass alle organisatorischen Abläufe reibungslos funktionieren und ist erste Ansprechpartnerin für viele interne und externe Anfragen."
        ctaText="Kontakt aufnehmen"
        ctaLink="#"
      />

      <ContactModal
        isOpen={showHansModal}
        onClose={() => setShowHansModal(false)}
        title="Senior Software Developer"
        imageSrc={translations.contact_1}
        description="Hans-Joachim Logemann ist einer unserer erfahrensten Softwareentwickler. Mit seiner technischen Expertise und seinem umfassenden Verständnis für komplexe Systeme trägt er maßgeblich zum Erfolg unserer Entwicklungsprojekte bei."
        ctaText="Kontakt aufnehmen"
        ctaLink="#"
      />
    </div>
  );
};

export default Contact;
