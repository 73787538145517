import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LanguageProvider } from './contexts/LanguageContext';
import HomePage from './pages/HomePage';
import ImprintPage from './pages/ImprintPage';
import PrivacyPage from './pages/PrivacyPage';
import PrivacySettingsPage from './pages/PrivacySettingsPage';
import PrivacyHistoryPage from './pages/PrivacyHistoryPage';
import ConsentRevokePage from './pages/ConsentRevokePage.tsx';

function App() {
  return (
    <LanguageProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/impressum" element={<ImprintPage />} />
          <Route path="/datenschutz" element={<PrivacyPage />} />
          <Route path="/Hinweisgebersystem" element={<PrivacySettingsPage />} />
          <Route path="/privacy-history" element={<PrivacyHistoryPage />} />
          <Route path="/consent-revoke" element={<ConsentRevokePage />} />

        </Routes>
      </Router>
    </LanguageProvider>
  );
}

export default App;
