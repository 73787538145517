export const imprintTranslations = {
    de: {
      copyRight: 'Copyright © 2024 Alle Rechte vorbehalten',
      title: 'Impressum',
      ownership: 'Inhalt, Layout und Text dieser Webseiten sind Eigentum der novobit group GmbH. Änderung und Irrtum vorbehalten. Stand 05/2023.',
      liability: 'Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.',
      company: 'novobit group GmbH',
      address: {
        street: 'Theodor-Heuss-Straße 2',
        city: '38122 Braunschweig',
        country: 'Deutschland'
      },
      contact: {
        phone: 'Phone: + 49 531 680940-0',
        fax: 'Fax: + 49 531 680940-99',
        email: 'Mail: mail [at] novobit.eu'
      },
      details: {
        manager: 'Vertretungsberechtigter Geschäftsführer: Hatem Hamzaoui',
        court: 'Registergericht: Amtsgericht Braunschweig',
        registration: 'HRB-Nr.: 4505',
        vatId: 'USt-IdNr.: DE 194 303 703'
      },
      responsible: {
        title: 'Verantwortlich für den Inhalt (gem. § 55 Abs. 2 RStV)',
        name: 'Hatem Hamzaoui'
      }
      
    },
    en: {
      copyRight: 'Copyright © 2024 all rights reserved',
      title: 'Imprint',
      ownership: 'The content, layout and text of these web pages are the property of novobit group GmbH. Subject to changes and errors. Version 05/2023.',
      liability: 'Disclaimer: despite careful control of the contents, we do not assume any liability for the contents of external links. The operators of the linked pages are solely responsible for their content.',
      company: 'novobit group GmbH',
      address: {
        street: 'Theodor-Heuss-Strasse 2',
        city: '38122 Brunswick',
        country: 'Germany'
      },
      contact: {
        phone: 'Phone: + 49 531 680940-0',
        fax: 'Fax: + 49 531 680940-99',
        email: 'Mail: mail [at] novobit.eu'
      },
      details: {
        manager: 'Director with Representation Authority: Hatem Hamzaoui',
        court: 'Registration court: Braunschweig district court',
        registration: 'HRB No.: 4505',
        vatId: 'VAT ID No.: DE 194 303 703'
      },
      responsible: {
        title: 'Responsible for content (in accordance with § 55, paragraph 2 of the RStV (German Broadcasting Corporation))',
        name: 'Hatem Hamzaoui'
      }
    },
    ar: {
      copyRight: 'حقوق الطبع والنشر © 2024 جميع الحقوق محفوظة',
      title: 'هيئة التحرير',
      ownership: 'محتوى وتصميم ونص هذه الصفحات هي ملك لشركة نوفوبت جروب. عرضة للتغيير والأخطاء. الحالة 05/2023.',
      liability: 'إشعار المسؤولية: على الرغم من التحكم الدقيق في المحتوى، لا نتحمل أي مسؤولية عن محتوى الروابط الخارجية. مشغلو الصفحات المرتبطة هم المسؤولون وحدهم عن محتواها.',
      company: 'نوفوبت جروب',
      address: {
        street: 'شارع ثيودور هويس 2',
        city: '38122 براونشفايغ',
        country: 'ألمانيا'
      },
      contact: {
        phone: 'هاتف: + 49 531 680940-0',
        fax: 'فاكس: + 49 531 680940-99',
        email: 'البريد الإلكتروني: mail [at] novobit.eu'
      },
      details: {
        manager: 'المدير التنفيذي: حاتم حمزاوي',
        court: 'محكمة التسجيل: محكمة منطقة براونشفايغ',
        registration: 'رقم السجل التجاري: 4505',
        vatId: 'رقم ضريبة القيمة المضافة: DE 194 303 703'
      },
      responsible: {
        title: 'المسؤول عن المحتوى (وفقاً للمادة 55 الفقرة 2 من قانون الإعلام)',
        name: 'حاتم حمزاوي'
      }
    }
  };
