import { useState } from 'react';
import AiModal from './AiExpertise/AiModal';
import ImageCard from './AiExpertise/ImageCard';
import { useLanguage } from '../contexts/LanguageContext';
import AiModalVideo from "./AiExpertise/AiModalVideo.tsx";

const AiExpertise = () => {
  const [showAiLabModal, setShowAiLabModal] = useState(false);
  
  // const [showTeamModal, setShowTeamModal] = useState(false);
  const [showSmartmapsModal, setShowSmartmapsModal] = useState(false);
  const [showRamziModal, setShowRamziModal] = useState(false);
  const [showSmartfakeModal, setShowSmartfakeModal] = useState(false);
  const [showVisionsModal, setShowVisionsModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showDeavllyModal, setShowDevallyModal] = useState(false);
  const [srcLinkVideo, setSrcLinkVideo] = useState('');
  const { translations } = useLanguage();
  
  


  return (
    <div id="ai-expertise" className="py-5 bg-white">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-light text-gray-900 mb-5">{translations.ai}</h2>
        
        <div className="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-3">
          <div   className="relative group col-span-2 cursor-pointer"    onClick={() => setShowAiLabModal(true)}   >
            <div className="relative h-full overflow-hidden rounded-lg">
              <img 
                src={translations.ki_expert_1_1}
                alt="AI Lab" 
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
              />
              <img
                  src={translations.ki_expert_1_1}
                alt="AI Lab Alternate" 
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
              <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="absolute bottom-6 left-6 text-white">
                  <p className="text-sm">{translations.ClickLearnMore}</p>
                </div>
              </div>
            </div>
          </div>

          <div     className="relative group col-span-2 cursor-pointer"  >
            <div className="relative h-full overflow-hidden rounded-lg">
              <img 
                src="../../assets/Pictures/KI-Team_novobit.jpg" 
                alt="AI Team" 
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
              />
              <img 
                src={translations.ki_expert_2} 
                alt="AI Team Alternate" 
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>

          <div 
            className="relative group col-span-1 cursor-pointer"
            onClick={() => setShowSmartmapsModal(true)}
          >
            <div className="relative h-full overflow-hidden rounded-lg">
              <img 
                src="../../assets/Pictures/smartmaps_novobit.jpg" 
                alt="Smartmaps" 
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
              />
              <img 
                src="../../assets/Pictures/smartmaps_novobit.jpg" 
                alt="Smartmaps Alternate" 
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
              <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="absolute bottom-6 left-6 text-white">
                  <p className="text-sm">{translations.ClickLearnMore}</p>
                </div>
              </div>
            </div>
          </div>

          <div 
            className="relative group col-span-1 cursor-pointer"
            //onClick={() => setShowRamziModal(true)}
          >
            <div className="relative h-full overflow-hidden rounded-lg">
              <img 
                src="../../assets/Pictures/Prof_Dr_Ramzi_Guetari_novobit.jpg" 
                alt="Prof. Dr. Ramzi Guetari" 
                className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
              />
              <img 
                src={translations.ki_expert_4}
                alt="Prof. Dr. Ramzi Guetari Alternate" 
                className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>

          <div className="relative h-full overflow-hidden rounded-lg col-span-2">
            <img 
              src="../../assets/Pictures/KI_Abteilung_novobit.jpg" 
              alt="KI Abteilung" 
              className="w-full h-full object-cover"
            />
          </div>

          <div className="relative group col-span-2">
            <ImageCard
              imageSrc="../../assets/Pictures/smartfake_KI_novobit.jpg"
              hoverImageSrc="../../assets/Pictures/smartfake_KI_novobit.jpg"
              title=" "
              onClick={() => setShowSmartfakeModal(true)}
            />
          </div>

          <div className="relative group col-span-2 cursor-pointer">
            <ImageCard
              imageSrc={translations.ki_expert_6_1}
              hoverImageSrc={translations.ki_expert_6_1}
              title=" "
              onClick={() => setShowVisionsModal(true)}
            />
          </div>

          <div className="relative h-full overflow-hidden rounded-lg col-span-2  cursor-pointer"   onClick={() => {
            setShowVideoModal(true); setSrcLinkVideo('../../assets/Animatics/novobit_smartglasses_Video.mp4')
          }}>
            <video
                poster="../../assets/Pictures/OGI_Film.jpg"
              muted 
              loop
              playsInline
              className={`absolute min-h-full min-w-full object-cover top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300`}
              style={{
                width: '100%',
                height: '100%'
              }}
            >
              <source src="../../assets/Animatics/novobit_smartglasses_Video.mp4" type="video/mp4" />
            </video>
          </div>

          <div className="relative h-full overflow-hidden rounded-lg col-span-1">
            <img 
              src="../../assets/Pictures/OGI_open_generated_information.jpg" 
              alt="AI Collaboration" 
              className="w-full h-full object-cover"
            />
          </div>

          <div className="relative h-full overflow-hidden rounded-lg col-span-1">
            <img 
              src="../../assets/Pictures/I_love_to_devally_Shirt.jpg" 
              alt="AI Collaboration" 
              className="w-full h-full object-cover"
            />
          </div>

          
          <div className="relative group col-span-2 cursor-pointer">
            <ImageCard
              imageSrc="../../assets/Pictures/Laptop_devally.jpg"
              hoverImageSrc="../../assets/Pictures/Laptop_devally.jpg"
              title=" "
              onClick={() => setShowDevallyModal(true)}
            />
          </div>

          <div className="relative h-full overflow-hidden rounded-lg col-span-2  cursor-pointer"  onClick={() => {
            setShowVideoModal(true); setSrcLinkVideo('../../assets/Animatics/devally_process_n.mp4')
          }}>
          <video
              poster="../../assets/Pictures/devally_Film_process.jpg"
              muted 
              loop
              
              className={`absolute min-h-full min-w-full object-cover top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300`}
              style={{
                width: '100%',
                height: '100%'
              }}
            >
              <source src="../../assets/Animatics/devally_process_n.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>

      <AiModal 
        isOpen={showAiLabModal}
        onClose={() => setShowAiLabModal(false)}
        title="AI Lab - Know-how ist das neue hip"
        imageSrc={translations.softwareservices_2_2}
        description="In unserem AI Lab entwickeln wir zukunftsweisende KI-Lösungen und erforschen neue Möglichkeiten der künstlichen Intelligenz. Unser erfahrenes Team verbindet theoretisches Wissen mit praktischer Anwendung, um innovative und effiziente KI-Systeme zu schaffen."
        ctaText="Mehr über unser AI Lab"
        ctaLink="#"
      />

      {/*<AiModal */}
      {/*  isOpen={showTeamModal}*/}
      {/*  onClose={() => setShowTeamModal(false)}*/}
      {/*  title="KI Team - Unsere Experten"*/}
      {/*  imageSrc={translations.ki_expert_2}*/}
      {/*  description="Unser KI-Team besteht aus hochqualifizierten Experten mit umfassender Erfahrung in den Bereichen Machine Learning, Deep Learning und KI-Entwicklung. Gemeinsam arbeiten wir an innovativen Lösungen, die die Grenzen der künstlichen Intelligenz erweitern und neue Maßstäbe in der Branche setzen."*/}
      {/*  ctaText="Lernen Sie unser Team kennen"*/}
      {/*  ctaLink="#"*/}
      {/*/>*/}

      <AiModal 
        isOpen={showSmartmapsModal}
        onClose={() => setShowSmartmapsModal(false)}
        title="Smartmaps - Intelligente Kartierung"
        imageSrc={translations.ki_expert_3}
        description="Mit Smartmaps bieten wir eine innovative KI-gestützte Lösung für intelligente Kartierung und räumliche Datenanalyse. Unsere Technologie ermöglicht präzise Visualisierungen und automatisierte Auswertungen für verschiedenste Anwendungsbereiche."
        ctaText="Mehr über Smartmaps"
        ctaLink="#"
      />

      <AiModal 
        isOpen={showRamziModal}
        onClose={() => setShowRamziModal(false)}
        title="Prof. Dr. Ramzi Guetari - KI-Experte"
        imageSrc="../../assets/Pictures/Prof_Dr_Ramzi_Guetari_novobit.jpg"
        description="Prof. Dr. Ramzi Guetari ist ein führender Experte im Bereich der Künstlichen Intelligenz und leitet unsere KI-Forschung und -Entwicklung. Mit seiner umfassenden Erfahrung und wissenschaftlichen Expertise treibt er die Innovation in unserem Unternehmen voran und gestaltet die Zukunft der KI-Technologie aktiv mit."
        ctaText="Mehr über Prof. Dr. Guetari"
        ctaLink="#"
      />

      <AiModal 
        isOpen={showSmartfakeModal}
        onClose={() => setShowSmartfakeModal(false)}
        title="Smartfake KI - Innovative KI-Lösungen"
        imageSrc={translations.ki_expert_5}
        description="Mit unserer Smartfake KI-Technologie entwickeln wir fortschrittliche Lösungen für die automatisierte Erkennung und Analyse von Datenmustern. Diese innovative Technologie ermöglicht es uns, komplexe Aufgaben effizient zu bewältigen und neue Perspektiven in der KI-Entwicklung zu erschließen."
        ctaText="Mehr über Smartfake KI"
        ctaLink="#"
      />

      <AiModal 
        isOpen={showVisionsModal}
        onClose={() => setShowVisionsModal(false)}
        title="Visions - Unsere KI-Vision"
        imageSrc={translations.ki_expert_6_2}
        description="Unsere Vision für KI-Technologie geht über das Gewöhnliche hinaus. Wir entwickeln zukunftsweisende Lösungen, die nicht nur technologisch fortschrittlich sind, sondern auch einen echten Mehrwert für Unternehmen und Gesellschaft schaffen. Mit innovativen Ansätzen und kreativen Ideen gestalten wir die Zukunft der künstlichen Intelligenz."
        ctaText="Mehr über unsere Vision"
        ctaLink="#"
      />

      <AiModal 
        isOpen={showDeavllyModal}
        onClose={() => setShowDevallyModal(false)}
        title="Visions - Unsere KI-Vision"
        imageSrc={translations.ki_expert_7}
        description="Unsere Vision für KI-Technologie geht über das Gewöhnliche hinaus. Wir entwickeln zukunftsweisende Lösungen, die nicht nur technologisch fortschrittlich sind, sondern auch einen echten Mehrwert für Unternehmen und Gesellschaft schaffen. Mit innovativen Ansätzen und kreativen Ideen gestalten wir die Zukunft der künstlichen Intelligenz."
        ctaText="Mehr über unsere Vision"
        ctaLink="#"
      />
      <AiModalVideo
          isOpen={showVideoModal}
          onClose={() => {
            setShowVideoModal(false)     ; setSrcLinkVideo('')
          }}
          srcLink={srcLinkVideo}
      />
    </div>
  );
};

export default AiExpertise;
