import React from 'react';
import { useLocation } from 'react-router-dom';
import HomeNavbar from '../components/navbar/HomeNavbar';
import PageNavbar from '../components/navbar/PageNavbar';
import Footer from '../components/Footer';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <div className="min-h-screen flex flex-col">
      {isHomePage ? <HomeNavbar /> : <PageNavbar />}
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;