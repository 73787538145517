import React from 'react';

interface JobsModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  imageSrc: string;
  description: string;
  ctaText?: string;
  ctaLink?: string;
   jobType: string
}

const JobsModal: React.FC<JobsModalProps> = ({
  isOpen,
  onClose,
  title,
  imageSrc,
 jobType  
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg max-w-6xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-start mb-4  sticky-btn ">
            <h3 className="text-2xl font-light"></h3>
            <button 
              onClick={onClose}
              className="bg-gray-400/50 hover:bg-gray-500/50 transition-colors rounded-full p-2"
            >
              <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="mb-4">
            <img 
              src={imageSrc}
              alt={title}
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
          {jobType === 'Tunisian' &&

              <div className="mt-6 flex justify-center">
                <a
                    href={'NG_FO_Job_Advertisement_HR-Manager.pdf'}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-click-primary mr-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 "
                >
                  HR Manager (m/w/f)
                </a>
                <a
                    href={'NG_FO_Job_Advertisement_Fullstack_developer .pdf'}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-click-primary mr-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700"
                >
                  Fullstack developer (m/f/d)
                </a>
                <a
                    href={'NG_FO_Job_Advertisement_Junior_IT-Administrator .pdf'}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-click-primary inline-flex items-center px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700"
                >
                   (Junior) IT-Administrator (m/f/d) 
                </a>
              </div>}
          {jobType === 'German' &&

              <div className="mt-6 flex justify-center">
                <a
                    href={'/NG_FO_Stellenausschreibung_Softwareentwickler.pdf'}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-click-primary inline-flex items-center px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700"
                >
                  Softwareentwickler (m/f/d)
                </a>
              </div>}
          
        </div>
      </div>
    </div>
  );
};

export default JobsModal;
