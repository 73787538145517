import { useState } from 'react';
import AkademieModal from './Akademie/AkademieModal';
import ImageCard from './Akademie/ImageCard';
import { useLanguage } from '../contexts/LanguageContext';


const Akademie = () => {
  const [showKiEntwicklerModal, setShowKiEntwicklerModal] = useState(false);
  const { translations } = useLanguage();

  return (
    <div id="akademie" className="py-5 bg-white">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-light text-gray-900 mb-5">{translations.academy}</h2>
        
        <div className="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-3">
          <div className="relative h-[120px] sm:h-[400px]  overflow-hidden rounded-lg col-span-2">
            <ImageCard
              imageSrc={translations.academy_1}
              hoverImageSrc={translations.academy_1}
              title=" "
              onClick={() => setShowKiEntwicklerModal(true)}
            />
          </div>
          
          <div className="relative h-[120px] sm:h-[400px]  overflow-hidden rounded-lg col-span-2">
            <img 
              src="../../assets/Pictures/Akademie_novobit_KI-Berater.jpg" 
              alt="Workshop" 
              className="w-full h-full object-cover"
            />
          </div>

          <div className="relative h-[120px] sm:h-[400px]  overflow-hidden rounded-lg col-span-1">
            <img 
              src="../../assets/Pictures/Sprachkurs_Jabari_Johnson.jpg" 
              alt="Training" 
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>

      <AkademieModal 
        isOpen={showKiEntwicklerModal}
        onClose={() => setShowKiEntwicklerModal(false)}
        title="KI-Entwickler Ausbildung"
        imageSrc={translations.academy_2}
        description="In unserer KI-Entwickler Ausbildung vermitteln wir fundiertes Wissen und praktische Fähigkeiten im Bereich der künstlichen Intelligenz. Von Machine Learning bis zu Deep Neural Networks - wir bereiten Sie optimal auf die Anforderungen der KI-Entwicklung vor."
        ctaText="Mehr zur Ausbildung"
        ctaLink="#"
      />
    </div>
  );
};

export default Akademie;
