import React from 'react';

interface AiModalProps {
  isOpen: boolean;
  onClose: () => void;
  srcLink: string
}

const AiModalVideo: React.FC<AiModalProps> = ({
  isOpen,
  onClose,
 srcLink
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg   w-full max-h-[90vh] overflow-y-auto max-width-71vw ">
        <div className="p-6">
          <div className="flex justify-between items-start mb-4  sticky-btn ">
            <h3 className="text-2xl font-light"></h3>
            <button 
              onClick={onClose}
              className="bg-gray-400/50 hover:bg-gray-500/50 transition-colors rounded-full p-2"
            >
              <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="aspect-video mb-4">
            <video
                controls
                autoPlay 
                loop
                playsInline
                style={{
                  width: '100%',
                  height: '100%'
                }}
            >
              <source src={srcLink} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiModalVideo;
