import { Link } from 'react-router-dom';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import {useLanguage} from "../contexts/language";
import {imprintTranslations} from "../translations/imprint.ts";
import {contactTranslations} from "../translations/privacy";
import {whistleblowerTranslations} from "../translations/whistleblower.ts";

const Footer = () => {

  const { language } = useLanguage();
  const t = imprintTranslations[language];
  const contact = contactTranslations[language];
  const whistleblower = whistleblowerTranslations[language];


  return (
    <footer className="bg-gray-100 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center space-y-6">
          <div className="text-sm text-gray-600 flex flex-wrap justify-center gap-x-2">
            <span>{t.copyRight}</span>
            <span>|</span>
            <Link to="/impressum" className="hover:text-gray-900">{t.title}</Link>
            <span>|</span>
            <Link to="/datenschutz" className="hover:text-gray-900">{contact.privacy}</Link>
            <span>|</span>
            <Link to="/Hinweisgebersystem" className="hover:text-gray-900">{whistleblower.title}</Link>
          </div>
          
          <div className="flex space-x-4">
            <a href="https://www.instagram.com/novobitgroup/" target="_blank" rel="noopener noreferrer" className="text-red-600 hover:text-red-700">
              <FaInstagram className="w-6 h-6" />
            </a>
            <a href="https://www.linkedin.com/company/recuitesoftwarengineerdesign/posts/?feedView=all" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700">
              <FaLinkedin className="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
