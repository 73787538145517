
const Hero = () => {

  return (
    <div className="relative h-screen">
       
      {/* Video Background */}
      <div className="absolute inset-0">
        <video 
          autoPlay 
          muted 
          playsInline
          className="absolute min-h-full min-w-full object-cover top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          <source src="../../assets/Animatics/novobit_web.mp4" type="video/mp4" />
          {/* Fallback image if video fails to load */}
          <img 
            src="/hero-bg.jpg" 
            alt="Hero Background" 
            className="absolute min-h-full min-w-full object-cover top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </video>
        {/* Overlay with responsive opacity */}
        <div className="absolute inset-0 bg-black/40 sm:bg-black/30"></div>
      </div>
    </div>
  );
};

export default Hero;