import Hero from '../components/Hero';
import About from '../components/About';
import News from '../components/News';
import CompanyStats from '../components/CompanyStats';
import SoftwareServices from '../components/SoftwareServices';
import AiExpertise from '../components/AiExpertise';
import Akademie from '../components/Akademie';
import Career from '../components/Career';
import Contact from '../components/Contact';
import Divider from '../components/Divider';
import MainLayout from '../layouts/MainLayout';
import {useEffect, useState} from "react";

const HomePage = () => {

 

    const [isVisible, setIsVisible] = useState(false)

    // handles the animation when scrolling to the top
    const scrollToTop = () => {
        isVisible &&
        window.scrollTo({
            top: 0,
            behavior: "auto",
        })
    }
    

    useEffect(() => {
        const toggleVisibility = () => {
            // if the user scrolls down, show the button
            window.scrollY > 800 ? setIsVisible(true) : setIsVisible(false)
        }
        // listen for scroll events
        window.addEventListener("scroll", toggleVisibility)

        // clear the listener on component unmount
        return () => {
            window.removeEventListener("scroll", toggleVisibility)
        }
    }, [])




    return (
    <MainLayout>
      <Hero /> 
      <About />
      <Divider />
      <News />
      <Divider />
      <CompanyStats />
      <Divider />
      <SoftwareServices />
      <Divider />
      <AiExpertise />
      <Divider />
      <Akademie />
      <Divider />
      <Career />
      <Divider />
      <Contact /> 

        <button
            className={`btn-scroll-top ${
                isVisible ? "opacity-100" : "opacity-0"
            }`}
            onClick={scrollToTop}
        >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={'3.5rem'}  height={'3.5rem'}
                     stroke="currentColor" className="size-11">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
        </button>
            
     
    </MainLayout>
  );
};

export default HomePage;
