import { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import CareerModal from './Career/CareerModal';
import ImageCard from './Career/ImageCard';
import JobsModal from "./Career/JobsModal.tsx";

const Career = () => {
  const { translations } = useLanguage();
  const [showBenefitsModal, setShowBenefitsModal] = useState(false);
  const [showViktoriaModal, setShowViktoriaModal] = useState(false);
  const [showJobBraunModal, setShowJobBraunModal] = useState(false);
  const [showJobTunisModal, setShowJobTunisModal] = useState(false);
  const [showTunisModal, setShowTunisModal] = useState(false);
  const [showNadaModal, setShowNadaModal] = useState(false);

  return (
    <div id="career" className="py-5 bg-white">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        {/* Braunschweig Section */}
        <div className="mb-10">
          <h2 className="text-3xl font-light text-gray-900 mb-5">{translations.careerBraunschweig}</h2>
          <div className="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-3">
            <div className="relative h-100 overflow-hidden rounded-lg col-span-3">
              <ImageCard
                imageSrc={translations.career_1_1}
                hoverImageSrc={translations.career_1_1}
                title="Benefits in Braunschweig"
                onClick={() => setShowBenefitsModal(true)}
              />
            </div>
            <div className="relative h-100 overflow-hidden rounded-lg col-span-1">
              <ImageCard
                imageSrc="../../assets/Pictures/Viktoria_Mahler_novobit.jpg"
                hoverImageSrc="../../assets/Pictures/Viktoria_Mahler_novobit.jpg"
                title="HR Management"
                onClick={() => setShowViktoriaModal(true)}
              />
            </div>
            <div className="relative h-100 overflow-hidden rounded-lg col-span-1">
              <ImageCard
                imageSrc="../../assets/Pictures/Jobs_novobit_Braunschweig.jpg"
                hoverImageSrc="../../assets/Pictures/Jobs_novobit_Braunschweig.jpg"
                title="HR Management"
                onClick={() => setShowJobBraunModal(true)}
              />
            </div>
          </div>
        </div>

        {/* Tunis Section */}
        <div>
          <h2 className="text-3xl font-light text-gray-900 mb-5">{translations.careerTunis}</h2>
          <div className="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-3">
            <div className="relative h-100 overflow-hidden rounded-lg col-span-3">
              <ImageCard
                imageSrc={translations.career_4_1}
                hoverImageSrc={translations.career_4_1}
                title="Tunis Office"
                onClick={() => setShowTunisModal(true)}
              />
            </div>

              <div className="relative h-100 overflow-hidden rounded-lg col-span-1">
                  <ImageCard
                      imageSrc="../../assets/Pictures/Jobs_novobit_Tunis.jpg"
                      hoverImageSrc="../../assets/Pictures/Jobs_novobit_Tunis.jpg"
                      title="HR Management"
                      onClick={() => setShowJobTunisModal(true)}
                  />
              </div>
            <div className="relative h-100 overflow-hidden rounded-lg col-span-1">
              <ImageCard
                imageSrc="../../assets/Pictures/Nada_Hamzaoui_novobit.jpg"
                hoverImageSrc="../../assets/Pictures/Nada_Hamzaoui_novobit.jpg"
                title="HR Management Tunis"
                onClick={() => setShowNadaModal(true)}
              />
            </div>
          </div>
        </div>
      </div>

      <CareerModal 
        isOpen={showBenefitsModal}
        onClose={() => setShowBenefitsModal(false)}
        title="Benefits in Braunschweig"
        imageSrc={translations.career_1_1_2}
        description="Bei novobit in Braunschweig bieten wir unseren Mitarbeitern ein attraktives Arbeitsumfeld mit vielen Vorteilen. Von flexiblen Arbeitszeiten über moderne Büroräume bis hin zu regelmäßigen Team-Events - wir legen Wert auf das Wohlbefinden und die Entwicklung unserer Mitarbeiter."
        ctaText="Mehr über unsere Benefits"
        ctaLink="#"
      />

      <CareerModal 
        isOpen={showViktoriaModal}
        onClose={() => setShowViktoriaModal(false)}
        title="HR Management"
        imageSrc={translations.career_2}
        description="Als HR-Managerin bei novobit gestaltet Viktoria Mahler die Zukunft unseres Teams. Mit ihrer Expertise in Personalentwicklung und Recruiting sorgt sie dafür, dass wir die besten Talente finden und fördern. Ihr Fokus liegt auf der Schaffung eines inspirierenden Arbeitsumfelds, in dem sich jeder Mitarbeiter optimal entwickeln kann."
        ctaText="Karriere bei novobit"
        ctaLink="#"
      />
      
      {/*<CareerModal */}
      {/*  isOpen={showJobBraunModal}*/}
      {/*  onClose={() => setShowJobBraunModal(false)}*/}
      {/*  title="HR Management"*/}
      {/*  imageSrc={translations.career_3_2}*/}
      {/*  description="Als HR-Managerin bei novobit gestaltet Viktoria Mahler die Zukunft unseres Teams. Mit ihrer Expertise in Personalentwicklung und Recruiting sorgt sie dafür, dass wir die besten Talente finden und fördern. Ihr Fokus liegt auf der Schaffung eines inspirierenden Arbeitsumfelds, in dem sich jeder Mitarbeiter optimal entwickeln kann."*/}
      {/*  ctaText="Karriere bei novobit"*/}
      {/*  ctaLink="#"*/}
      {/*/>*/}
      
      {/*<CareerModal */}
      {/*  isOpen={showJobTunisModal}*/}
      {/*  onClose={() => setShowJobTunisModal(false)}*/}
      {/*  title="HR Management"*/}
      {/*  imageSrc={translations.career_6_2}*/}
      {/*  description="Als HR-Managerin bei novobit gestaltet Viktoria Mahler die Zukunft unseres Teams. Mit ihrer Expertise in Personalentwicklung und Recruiting sorgt sie dafür, dass wir die besten Talente finden und fördern. Ihr Fokus liegt auf der Schaffung eines inspirierenden Arbeitsumfelds, in dem sich jeder Mitarbeiter optimal entwickeln kann."*/}
      {/*  ctaText="Karriere bei novobit"*/}
      {/*  ctaLink="#"*/}
      {/*/>*/}


        <JobsModal

            isOpen={showJobBraunModal}
            onClose={() => setShowJobBraunModal(false)}
            title="HR Management"
            imageSrc={translations.career_3_2}
            description="Als HR-Managerin bei novobit gestaltet Viktoria Mahler die Zukunft unseres Teams. Mit ihrer Expertise in Personalentwicklung und Recruiting sorgt sie dafür, dass wir die besten Talente finden und fördern. Ihr Fokus liegt auf der Schaffung eines inspirierenden Arbeitsumfelds, in dem sich jeder Mitarbeiter optimal entwickeln kann."
            ctaText="Karriere bei novobit"
            ctaLink="#"
            jobType={'German'}
        />

        <JobsModal 
            isOpen={showJobTunisModal}
            onClose={() => setShowJobTunisModal(false)}
            title="HR Management"
            imageSrc={translations.career_6_2}
            description="Als HR-Managerin bei novobit gestaltet Viktoria Mahler die Zukunft unseres Teams. Mit ihrer Expertise in Personalentwicklung und Recruiting sorgt sie dafür, dass wir die besten Talente finden und fördern. Ihr Fokus liegt auf der Schaffung eines inspirierenden Arbeitsumfelds, in dem sich jeder Mitarbeiter optimal entwickeln kann."
            ctaText="Karriere bei novobit"
            ctaLink="#"
            jobType={'Tunisian'}
            
        />




        <CareerModal 
        isOpen={showTunisModal}
        onClose={() => setShowTunisModal(false)}
        title="novobit Tunis"
        imageSrc={translations.career_1_2}
        description="Unser Standort in Tunis ist ein wichtiger Teil der novobit-Familie. Mit modernen Büroräumen im Herzen der Stadt bieten wir unseren Mitarbeitern ein inspirierendes Arbeitsumfeld mit mediterranem Flair. Die enge Zusammenarbeit mit unserem Team in Braunschweig ermöglicht einen wertvollen kulturellen Austausch und innovative Projektarbeit."
        ctaText="Karriere in Tunis"
        ctaLink="#"
      />

      <CareerModal 
        isOpen={showNadaModal}
        onClose={() => setShowNadaModal(false)}
        title="HR Management Tunis"
        imageSrc={translations.career_5}
        description="Als HR-Managerin in unserem Tunis-Büro spielt Nada Hamzaoui eine Schlüsselrolle bei der Entwicklung unseres Teams in Tunesien. Mit ihrer umfassenden Erfahrung in der Personalentwicklung und ihrem tiefen Verständnis für beide Kulturen trägt sie maßgeblich zum Erfolg unserer internationalen Zusammenarbeit bei."
        ctaText="Karriere in Tunis"
        ctaLink="#"
      />
    </div>
  );
};

export default Career;
