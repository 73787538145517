export const generalInfoTranslations = {
  de: {
    consent: 'Durch die Nutzung unserer Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten gemäß der nachfolgenden Beschreibung einverstanden:',
    usage: 'Unsere Website kann grundsätzlich ohne Registrierung besucht werden. Dabei werden allerdings schon Daten wie beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden. Personenbezo-gene Daten indes, insbesondere Name, Adresse oder E-Mail-Adresse werden soweit möglich nur auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an Dritte.'
  },
  en: {
    consent: 'By using our website, you agree to the collection, processing, and use of data in accordance with the following description:',
    usage: 'Our website can generally be visited without registration. However, data such as pages accessed or names of retrieved files, date and time are stored on the server for statistical purposes, without these data being directly related to your person. Personal data, particularly name, address, or email address, are only collected on a voluntary basis where possible. Without your consent, no data will be passed on to third parties.'
  },
  ar: {
    consent: 'باستخدام موقعنا الإلكتروني، فإنك توافق على جمع ومعالجة واستخدام البيانات وفقاً للوصف التالي:',
    usage: 'يمكن زيارة موقعنا الإلكتروني بشكل عام دون تسجيل. ومع ذلك، يتم تخزين بيانات مثل الصفحات التي تم الوصول إليها أو أسماء الملفات المسترجعة والتاريخ والوقت على الخادم لأغراض إحصائية، دون أن تكون هذه البيانات مرتبطة مباشرة بشخصك. يتم جمع البيانات الشخصية، وخاصة الاسم أو العنوان أو عنوان البريد الإلكتروني، على أساس طوعي فقط حيثما أمكن ذلك. لن يتم نقل أي بيانات إلى أطراف ثالثة دون موافقتك.'
  }
};
