import React from 'react';
import {useLanguage} from "../../contexts/LanguageContext.tsx";

interface ImageCardProps {
  imageSrc: string;
  hoverImageSrc?: string;
  title?: string;
  onClick?: () => void;
  showOverlay?: boolean;
}

const ImageCard: React.FC<ImageCardProps> = ({
  imageSrc,
  hoverImageSrc,
  title,
  onClick,
  showOverlay = true
}) => {

  const { translations } = useLanguage();
  
  return (
    <div 
      className={`relative group h-full overflow-hidden rounded-lg ${onClick ? 'cursor-pointer' : ''}`}
      onClick={onClick}
    >
      <div className="relative h-full">
        <img 
          src={imageSrc}
          alt={title || "Image"}
          className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-0"
        />
        
        {hoverImageSrc && (
          <img 
            src={hoverImageSrc}
            alt={`${title || 'Image'} Alternate`}
            className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          />
        )}

        {showOverlay && (
          <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            {title && (
              <div className="absolute bottom-6 left-6 text-white">
                {onClick && <p className="text-sm">{translations.ClickLearnMore}</p>}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCard;
