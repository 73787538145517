export const contactTranslations = {
  de: {
    privacy: 'Datenschutzerklärung',
    title: 'Name und Anschrift des Verantwortlichen',
    description: 'Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist die:',
    company: {
      name: 'novobit group GmbH',
      manager: 'Hatem Hamzaoui (Geschäftsführung)',
      address: 'Theodor-Heuss-Straße 2',
      city: '38122 Braunschweig',
      phone: 'Telefon: +49 (0) 531 680 940 0',
      email: 'E-Mail: mail@novobit.eu',
      website: 'Website: www.novobit.eu'
    }
  },
  en: {
    privacy: 'Privacy Policy',
    title: 'Name and Address of the Controller',
    description: 'The controller within the meaning of the General Data Protection Regulation and other national data protection laws of the member states as well as other data protection regulations is:',
    company: {
      name: 'novobit group GmbH',
      manager: 'Hatem Hamzaoui (Management)',
      address: 'Theodor-Heuss-Strasse 2',
      city: '38122 Brunswick',
      phone: 'Phone: +49 (0) 531 680 940 0',
      email: 'Email: mail@novobit.eu',
      website: 'Website: www.novobit.eu'
    }
  },
  ar: {
    privacy: 'سياسة الخصوصية',
    title: 'اسم وعنوان المسؤول',
    description: 'المسؤول في إطار اللائحة العامة لحماية البيانات وقوانين حماية البيانات الوطنية الأخرى للدول الأعضاء وكذلك لوائح حماية البيانات الأخرى هو:',
    company: {
      name: 'نوفوبت جروب',
      manager: 'حاتم حمزاوي (الإدارة)',
      address: 'شارع ثيودور هويس 2',
      city: '38122 براونشفايغ',
      phone: 'هاتف: +49 (0) 531 680 940 0',
      email: 'البريد الإلكتروني: mail@novobit.eu',
      website: 'الموقع الإلكتروني: www.novobit.eu'
    }
  }
};
