export const externalLinksTranslations = {
  de: {
    title: 'Externe Links',
    description: 'Diese Website enthält Verknüpfungen zu Websites Dritter (sog. „externe Links“). Diese Websites unterliegen\n' +
        'allerdings allein der Haftung und Verantwortung der jeweiligen Betreiber. novobit group GmbH hat keinen\n' +
        'Einfluss auf die aktuelle und zukünftige Gestaltung sowie auf die Inhalte der verknüpften Seiten. Das\n' +
        'Verknüpfen dieser externen Links bedeutet allerdings nicht, dass sich die novobit group GmbH die Inhalte\n' +
        'hinter den Links zu eigen macht. Eine (ständige) Kontrolle dieser externen Links wird von der novobit group\n' +
        'GmbH weder ohne konkrete Hinweise auf Rechtsverstöße durchgeführt noch ist das zumutbar. Bei Kenntnis\n' +
        'von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.'
  },
  en: {
    title: 'External Links',
    description: 'This website contains links to third-party websites (so-called "external links"). However, these websites are\n' +
        'solely subject to the liability and responsibility of the respective operators. novobit group GmbH has no\n' +
        'influence on the current and future design and content of the linked sites. However, the linking of these\n' +
        'external links does not mean that novobit group GmbH adopts the contents behind the links as its own. A\n' +
        '(constant) control of these external links is neither carried out by novobit group GmbH without concrete\n' +
        'indications of legal violations nor is this reasonable. However, such external links will be deleted\n' +
        'immediately if legal violations become known.'
  },
  ar: {
    title: 'الروابط الخارجية',
    description: 'هذا الموقع يحتوي على روابط لمواقع إلكترونية تابعة لأطراف ثالثة (ما يُعرف بـ"روابط خارجية"). ومع ذلك، تقع مسؤولية وإدارة هذه المواقع بالكامل على عاتق مشغليها. لا تملك شركة novobit group GmbH أي تأثير على التصميم الحالي أو المستقبلي أو على محتوى هذه المواقع المرتبطة.\n' +
        '\n' +
        'إن وجود هذه الروابط الخارجية لا يعني أن novobit group GmbH تتبنى أو توافق على محتويات هذه المواقع. كما أن الشركة لا تقوم بمراجعة مستمرة لهذه الروابط الخارجية إلا في حالة وجود إشارات واضحة إلى انتهاكات قانونية، ولا يُتوقع منها القيام بذلك. ومع ذلك، إذا تم التحقق من وجود انتهاكات قانونية، فسيتم حذف هذه الروابط الخارجية على الفور.'
  }
};
