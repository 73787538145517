import MainLayout from '../layouts/MainLayout';
import { useLanguage } from '../contexts/LanguageContext';
import { whistleblowerTranslations } from '../translations/whistleblower';

const PrivacySettingsPage = () => {
  const { language } = useLanguage();
  const t = whistleblowerTranslations[language];
  const handleReportClick = () => {
    window.open('https://www.hinweisgeberschutz.me/kontakt/', '_blank');
  };

  return (
    <MainLayout>
      <div className="max-w-4xl mx-auto px-4 py-6 mt-20">
        <h1 className="text-3xl font-light ">{t.title}</h1>
        <div className="space-y-8">
          {/* Introduction */}
          <div className="prose max-w-none mt-3">
            <p className="text-gray-700">{t.introduction}</p>
          </div>

          {/* How to Report */}
          <div className="prose max-w-none">
            <h2 className="text-1xl font-medium mb-2 ">{t.howToReport.title}</h2>
            <p className="text-gray-700">{t.howToReport.content}</p>
          </div>

          {/* How to Formulate */}
          <div className="prose max-w-none">
            <h2 className="text-1xl font-medium mt-3 mb-2">{t.howToFormulate.title}</h2>
            <p className="text-gray-700 mb-4">{t.howToFormulate.introduction}</p>
            <ol className="list-decimal list-inside space-y-2">
              {t.howToFormulate.questions.map((question, index) => (
                <li key={index} className="text-gray-700">{question}</li>
              ))}
            </ol>
          </div>

          {/* Report Button */}
           <div className="flex justify-center pt-8">
            <button
              onClick={handleReportClick}
              className="bg-red-600 hover:bg-red-700 text-white px-8 py-4 rounded-md transition-colors duration-200 font-medium text-lg"
            >
              {t.btnHinweisgeben}
            </button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default PrivacySettingsPage;
