import MainLayout from '../layouts/MainLayout';

const ConsentRevokePage = () => {
  return (
    <MainLayout>
      <div className="max-w-7xl mx-auto px-4 py-8 mt-20">
        <h1 className="text-3xl font-light mb-6">Einwilligungen widerrufen</h1>
        <div className="space-y-6">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-light mb-4">Aktive Einwilligungen</h2>
            <div className="space-y-4">
              <div className="flex items-center justify-between p-4 border rounded-lg">
                <div>
                  <h3 className="font-medium">Newsletter</h3>
                  <p className="text-sm text-gray-600">Erteilt am: 15.12.2023</p>
                </div>
                <button className="text-red-600 hover:text-red-700">
                  Widerrufen
                </button>
              </div>

              <div className="flex items-center justify-between p-4 border rounded-lg">
                <div>
                  <h3 className="font-medium">Analyse Cookies</h3>
                  <p className="text-sm text-gray-600">Erteilt am: 10.12.2023</p>
                </div>
                <button className="text-red-600 hover:text-red-700">
                  Widerrufen
                </button>
              </div>

              <div className="flex items-center justify-between p-4 border rounded-lg">
                <div>
                  <h3 className="font-medium">Marketing Cookies</h3>
                  <p className="text-sm text-gray-600">Erteilt am: 05.12.2023</p>
                </div>
                <button className="text-red-600 hover:text-red-700">
                  Widerrufen
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-light mb-4">Widerrufene Einwilligungen</h2>
            <div className="space-y-4">
              <div className="flex items-center justify-between p-4 border rounded-lg opacity-50">
                <div>
                  <h3 className="font-medium">Personalisierte Werbung</h3>
                  <p className="text-sm text-gray-600">Widerrufen am: 01.12.2023</p>
                </div>
                <button className="text-blue-600 hover:text-blue-700">
                  Erneut erteilen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ConsentRevokePage;