import MainLayout from '../layouts/MainLayout';
import { useLanguage } from '../contexts/LanguageContext';
import {
  contactTranslations,
  dataProtectionOfficerTranslations,
  generalInfoTranslations,
  cookiesTranslations,
  accessTranslations,
  supervisoryTranslations,
  securityTranslations,
  externalLinksTranslations,
  serverLogsTranslations,
  socialMediaTranslations,
  youtubeTranslations
} from '../translations/privacy';

const PrivacyPage = () => {
  const { language } = useLanguage();
  const contact = contactTranslations[language];
  const dpo = dataProtectionOfficerTranslations[language];
  const general = generalInfoTranslations[language];
  const cookies = cookiesTranslations[language];
  const access = accessTranslations[language];
  const supervisory = supervisoryTranslations[language];
  const security = securityTranslations[language];
  const externalLinks = externalLinksTranslations[language];
  const serverLogs = serverLogsTranslations[language];
  const social = socialMediaTranslations[language];
  const youtube = youtubeTranslations[language];

  return (
    <MainLayout>
      <div className="max-w-7xl mx-auto px-4 py-8 mt-20">
        <h1 className="text-3xl font-light mb-2">{contact.privacy}</h1>
        <div className="prose max-w-none space-y-2">
          {/* Contact Section */}
          <section>
            <p className="text-1xl font-medium font-light mb-2">{contact.title}</p>
            <p className="text-gray-700">{contact.description}</p>
            <div className="mt-4">
              <p className="font-medium">{contact.company.name}</p>
              <p className="text-gray-700">
                {contact.company.manager}<br />
                {contact.company.address}<br />
                {contact.company.city}<br />
                {contact.company.phone}<br />
                {contact.company.email}<br />
                {contact.company.website}
              </p>
            </div>
          </section>

          {/* Data Protection Officer Section */}
          <section>
            <h2 className="text-1xl   mb-2 mt-5 font-medium">{dpo.title}</h2>
            <p className="text-gray-700">{dpo.description}</p>
            <div className="mt-2">
              <p className="text-gray-700">
                {dpo.contact.name}<br />
                {dpo.contact.address}<br />
                {dpo.contact.city}<br />
                {dpo.contact.phone}<br />
                {dpo.contact.fax}<br />
                {dpo.contact.email}
              </p>
            </div>
          </section>

          {/* General Information Section */}
          <section>
            <p className="text-gray-700">{general.consent}</p>
            <p className="text-gray-700 mt-2">{general.usage}</p>
          </section>

          {/* Access Section */}
          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{access.title}</h2>
            <p className="text-gray-700">{access.description}</p>
          </section>

          {/* Supervisory Authority Section */}
          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5" >{supervisory.title}</h2>
            <p className="text-gray-700">{supervisory.description}</p>
            <div>
              <p className="text-gray-700">
                {supervisory.authority.name}<br />
                {supervisory.authority.address}<br />
                {supervisory.authority.city}<br />
                {supervisory.authority.phone}<br />
                {supervisory.authority.fax}<br />
                {supervisory.authority.email}
              </p>
            </div>
          </section>

          {/* Data Security Section */}
          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{security.title}</h2>
            <p className="text-gray-700">{security.description}</p>
          </section>

          {/* Cookies Section */}
          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{cookies.title}</h2>
            <p className="text-gray-700">{cookies.description}</p>
            <p className="text-gray-700">{cookies.description2}</p>


            <h2 className="text-1xl font-medium mb-2 mt-5">{cookies.titleLinkGoogleMaps}</h2>
            <p className="text-gray-700">{cookies.linkGoogleMaps}</p>
            <a href={'https://policies.google.com/privacy?gl=DE&hl=de'}>https://policies.google.com/privacy?gl=DE&hl=de</a>
           </section>

          {/* External Links Section */}
          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{externalLinks.title}</h2>
            <p className="text-gray-700">{externalLinks.description}</p>
          </section>

          {/* Server Log Files Section */}
          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{serverLogs.title}</h2>
            <p className="text-gray-700">{serverLogs.description}</p>
            <ul className="list-disc list-inside mt-4 text-gray-700">
              {serverLogs.dataTypes.map((type, index) => (
                <li key={index}>{type}</li>
              ))}
            </ul>
            <p className="text-gray-700 mt-4">{serverLogs.usage}</p>
          </section>
          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{serverLogs.Aktualität}</h2>
            <p className="text-gray-700">{serverLogs.AktualitätDesc}</p>
          </section>
          {/* Social Media Sections */}

          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{social.facebook.title}</h2>
            <p className="text-gray-700">{social.facebook.description}</p>
            <a href={'https://de-de.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0'}>
              https://de-de.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0</a>
          </section>

          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{social.facebook.facebookFanpage}</h2>
            <p className="text-gray-700">{social.facebook.facebookFanpageDesc}</p>
            <a  href={'https://de-de.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0'}>https://de-de.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0</a>
          </section>
          
          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{social.instagram.title}</h2>
            <p className="text-gray-700">{social.instagram.description}</p>
          </section>

          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{social.linkedin.title}</h2>
            <p className="text-gray-700">{social.linkedin.description}</p>
            <a  href={'https://fr.linkedin.com/legal/privacy-policy?'}>https://fr.linkedin.com/legal/privacy-policy?</a>

          </section>

          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{social.xing.title}</h2>
            <p className="text-gray-700">{social.xing.description}</p>
            <a  href={'https://privacy.xing.com/de/datenschutzerklaerung'}>https://privacy.xing.com/de/datenschutzerklaerung</a>

          </section>

          {/* YouTube Section */}
          <section>
            <h2 className="text-1xl font-medium mb-2 mt-5">{youtube.title}</h2>
            <p className="text-gray-700">{youtube.description}</p>            
            <a  href={'https://policies.google.com/privacy?hl=de&gl=de'}>https://policies.google.com/privacy?hl=de&gl=de</a>

           </section>
        </div>
      </div>
    </MainLayout>
  );
};

export default PrivacyPage;
