import { ReactNode } from 'react';
import { LanguageProvider as BaseLanguageProvider } from './language/LanguageContext';
import { useLanguageProvider } from './language/useLanguage';

interface LanguageProviderProps {
  children: ReactNode;
}

export function LanguageProvider({ children }: LanguageProviderProps) {
  const languageState = useLanguageProvider();

  return (
    <BaseLanguageProvider value={languageState}>
      {children}
    </BaseLanguageProvider>
  );
}

export { useLanguage } from './language/useLanguage';