import { createContext, ReactNode } from 'react';
import { LanguageContextType } from './types';

export const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

interface LanguageProviderProps {
  children: ReactNode;
  value: LanguageContextType;
}

export function LanguageProvider({ children, value }: LanguageProviderProps) {
  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}